import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Strict Moderation`}</h1>
    <ul>
      <li parentName="ul">{`An option for `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{` `}<a parentName="li" {...{
          "href": "/Moderation",
          "title": "👹 Moderation"
        }}>{`👹 Moderation`}</a>{` in `}<a parentName="li" {...{
          "href": "/configuration",
          "title": "configuration"
        }}>{`configuration`}</a>{`. If enabled, no `}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{` are shown without moderator approval.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      